import { ActionButton } from 'components/Cards/Cards.shared.styled';
import CustomLink from 'components/CustomLink';
import useApiUrl from 'hooks/useApiUrl';
import { ButtonCard, OrdinaryCard } from 'types/componentTypes';
import { ComponentType } from 'types/constantTypes';
import {
    CardWrapper,
    CardBody,
    Img,
    Title,
    DescriptionWrapper,
    ImgWrapper,
    MainContentWrapper,
    StyledRichText,
} from './MediumCard.styled';

const MediumCard: React.FC<OrdinaryCard | ButtonCard> = ({ value, type }) => {
    const apiUrl = useApiUrl();
    const actionUrl = value.action?.action?.url;

    const OrdinaryCard: React.FC<{ actionUrl?: string }> = ({ actionUrl }) => {
        return (
            <CardWrapper $type={type}>
                <CardBody>
                    <MainContentWrapper $hasAction={!!actionUrl}>
                        {value.illustration && (
                            <ImgWrapper>
                                <Img
                                    src={`${apiUrl}${value.illustration.url}`}
                                />
                            </ImgWrapper>
                        )}

                        <Title>{value.title}</Title>

                        <DescriptionWrapper>
                            <StyledRichText value={value.description} />
                        </DescriptionWrapper>
                    </MainContentWrapper>

                    {actionUrl && (
                        <CustomLink href={value.action?.action?.url}>
                            <ActionButton $color={value.action?.accentColor}>
                                {value?.action?.title}
                            </ActionButton>
                        </CustomLink>
                    )}
                </CardBody>
            </CardWrapper>
        );
    };

    if (type === ComponentType.buttonCard) {
        return (
            <CustomLink href={`${apiUrl}${value.action.action.url}`}>
                <OrdinaryCard />
            </CustomLink>
        );
    }

    if (type === ComponentType.ordinaryCard) {
        return <OrdinaryCard actionUrl={actionUrl} />;
    }

    return null;
};

export default MediumCard;
