import { transientOptions } from './../../helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';

interface ColorProps {
    $color?: string;
}

export const ActionButton = styled('button')<ColorProps>(({ theme, $color }) =>
    mq({
        ...theme.fonts.link_button,
        width: '160px',
        height: '40px',
        background:
            $color === 'primary'
                ? theme.colors.primaryColor
                : theme.colors.secondaryColor_basic,
        borderRadius: '50px',
        color:
            $color === 'primary'
                ? theme.colors.white
                : theme.colors.primaryText,
        border: 'none',
        cursor: 'pointer',
    })
);

export const DescriptionWrapperShared = styled('div')(({ theme }) =>
    mq({
        maxWidth: ['71%', '92%', '79%'],
        flex: '1 1 max-content',
        display: 'flex',
        textAlign: 'left',
        justifyContent: 'center',
        alignItems: 'flex-start',
        color: theme.colors.primaryText,

        '& p': {
            ...theme.fonts.body_4,
            marginTop: '0px',
            textOverflow: 'ellipsis',
        },
    })
);

export interface MainContentWrapperSharedProps {
    $hasAction: boolean;
}

export const MainContentWrapperShared = styled(
    'div',
    transientOptions
)<MainContentWrapperSharedProps>(({ $hasAction }) =>
    mq({
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: $hasAction ? '180px' : '100%',
        position: 'relative',

        '&::after': {
            background: 'linear-gradient(0deg,#fff,rgba(248,250,252,0))',
            bottom: '0',
            content: '""',
            position: 'absolute',
            right: '0',
            width: '100%',
            height: '25px',
        },
    })
);
