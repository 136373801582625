import { transientOptions } from 'helpers/transientOptions';
import { styled } from '@mui/material';
import { mq } from 'theme/mediaQueries';
import { mobileStyles } from 'types/displayTypeStyles';
import { ComponentType } from 'types/constantTypes';

interface CardWrapperProps {
    $type: ComponentType;
}

interface CardBodyProps {
    $hasAction: boolean;
}

export const CardWrapper = styled(
    'div',
    transientOptions
)<CardWrapperProps>(({ $type }) =>
    mq({
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        cursor: $type === ComponentType.customIllustrationCards && 'pointer',

        [mobileStyles]: {
            margin: '5px 5px 13px 5px',
        },
    })
);

export const ButtonCardWrapper = styled('div')(() => ({
    cursor: 'pointer',
}));

export const CardBody = styled('div')<CardBodyProps>(({ $hasAction }) =>
    mq({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        padding: '18px 16px 40px',
        width: ['320px', '347px', '347px'],
        height: $hasAction ? ['450px', '480px', '480px'] : ['400px', '425px', '425px'],
        boxShadow: '0px 4px 20px 0px rgba(17, 24, 33, 0.25)',
        borderRadius: '10px',
    })
);

export const Arrow = styled('img')(() =>
    mq({
        margin: ['0px 6px', '0px 10px 0px 14px'],
        maxWidth: '21px',
        maxHeight: '15',
    })
);

export const MainContentWrapper = styled('div')(() =>
    mq({
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    })
);

export const ImgWrapper = styled('div')(() =>
    mq({
        height: '350px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    })
);

export const TitleWrapper = styled('div')(() => ({
    maxWidth: '295px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 0 0 35px',
}));

export const Title = styled('div')(({ theme }) => {
    return mq({
        ...theme.fonts.h5_headline,
        color: theme.colors.primaryText,
        fontWeight: '600 !important',
        textAlign: 'center',
    });
});

export const Step = styled('div')(({ theme }) => ({
    ...theme.fonts.h3_headline,
    position: 'relative',
    top: '8px',
    left: '9px',

    width: '66px',
    height: '67px',
    padding: '14px 16px 7px 20px',
    textAlign: 'center',
    borderRadius: '226px 50px 227px 240px',
    fontWeight: '600',
    background: theme.colors.white,
    color: theme.colors.primaryColor,
}));

export const StepWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: '-21px',
    left: '-17px',
    width: '75px',
    height: '75px',

    boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '230px 20px 227px 240px',
    background: theme.colors.primaryColor,
}));
