import { CardsWrapper } from './CustomIllustrationCards.styled';
import CustomIllustrationCard from './CustomIllustrationCard';
import type { CustomIllustrationCards } from 'types/componentTypes';
import { useMemo } from 'react';

const CustomIllustrationCards: React.FC<CustomIllustrationCards> = ({
    cards,
}) => {
    if (!cards) return null;

    const lastItemId = useMemo(() => cards.length - 1, [cards]);

    return (
        <CardsWrapper className="cards">
            {cards.map((card, idx) => {
                const isLastItem = lastItemId === idx;

                return (
                    <CustomIllustrationCard
                        key={card.id}
                        cardNumber={idx + 1}
                        {...card}
                        lastItem={isLastItem}
                    />
                );
            })}
        </CardsWrapper>
    );
};

export default CustomIllustrationCards;
