import { CardsWrapper } from './MarketingCards.styled';
import MarketingCard from './MarketingCard/MarketingCard';
import type { MarketingCards } from 'types/componentTypes';
import { useMemo } from 'react';

const MarketingCards: React.FC<MarketingCards> = ({ cards }) => {
    if (!cards) return null;

    const lastItemId = useMemo(() => cards.length - 1, [cards]);

    return (
        <CardsWrapper className="cards">
            {cards.map((card, idx) => {
                const isLastItem = lastItemId === idx;

                return (
                    <MarketingCard
                        key={card.id}
                        {...card}
                        lastItem={isLastItem}
                    />
                );
            })}
        </CardsWrapper>
    );
};

export default MarketingCards;
