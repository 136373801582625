import { CardsWrapper } from './MediumCards.styled';
import MediumCard from './MediumCard/MediumCard';
import type { MediumCards } from 'types/componentTypes';
import { useMemo } from 'react';

const MediumCards: React.FC<MediumCards> = ({ cards }) => {
    if (!cards) return null;

    const lastItemId = useMemo(() => cards.length - 1, [cards]);

    return (
        <CardsWrapper className="cards">
            {cards.map((card, idx) => {
                const isLastItem = lastItemId === idx;

                return (
                    <MediumCard
                        key={card.id}
                        {...card}
                        lastItem={isLastItem}
                    />
                );
            })}
        </CardsWrapper>
    );
};

export default MediumCards;
